import { Component, ViewChild } from '@angular/core';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { BannersService } from 'src/app/services/banners.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'PromocionesCR';

  images: any = []

  public isAdi = '';
  public email = '';

  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;

  constructor(
    private bannersService: BannersService,
    private http: HttpClient
    ){    
  }

  @ViewChild('carousel', {static : true}) carousel: NgbCarousel;

  ngOnInit() {    
    this.bannersService.GetLandingBanners().subscribe(banners => {
      
      let banners_array = Object.values(banners);
      console.log(banners_array);

      banners_array.forEach((element, index) => {
        this.images[index] = element.URLImagen;
      });
      console.log(this.images);

    });
  }

  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  onSlide(slideEvent: NgbSlideEvent) {
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }

  public guardar(){
    let arroba = this.email.includes('@');
    let punto = this.email.includes('.');  

    if(arroba == true && punto == true ){
      if(this.isAdi != null && this.isAdi != "")
      {
          this.http.post("https://vianneymobile-dot-mx-vianney-001.appspot.com/api/suscriptorLandingPage", {
            correo : this.email,
            origen : "MX",
            afiliado : this.isAdi,
            descripcion : "LANDING PROMOCIONES CR DICIEMBRE 2021"
          }).subscribe(response => {
              //console.log('Response is ', response);
              Swal.fire(
                '',
                'Gracias por registrarse!',
                'success'
              ).then((result) => {
                if (result.value) {
                    location.reload();
                }
              }) 
          }, err => {
            Swal.fire({
              type: 'error',
              text: 'Lo sentimos no se pudo registrar, intentelo de nuevo.'
            })
          });
          
      }
      else{
        Swal.fire({
          type: 'error',
          text: 'Seleccione una opcion!'
        })
      }     
    }
    else{
      Swal.fire({
        type: 'error',
        text: 'Ingrese un correo valido!'
      })
    }
  }
}
